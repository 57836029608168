import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, Auth } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { isEmpty } from '@aws-amplify/core';
import { LoadingStatus, UserAuthContext } from 'src/components/context/AppContextModel';
import { LoadingSpinner } from 'src/components/generic-components/Spinner';
import { configureTheLogger } from '../../utils/Logger';
import { AUTH_TYPE } from 'aws-appsync';
import { retrieveAPIKey } from 'src/utils/AWSServices';

const initialData: UserAuthContext = { userAuthDataLoadingStatus: LoadingStatus.Loading } as UserAuthContext;
const AuthContextDetails = createContext(initialData);
export const useAuth = () => useContext(AuthContextDetails);

// Provider component that wraps your app and makes auth object
export const AuthProvider = ({ children }: any) => {
  const userAuthData: UserAuthContext = useAuthProvider();
  if (userAuthData?.userAuthDataLoadingStatus === LoadingStatus.Loading) {
    return <LoadingSpinner />;
  } else {
    configureTheLogger(userAuthData.Alias, userAuthData);
    return <AuthContextDetails.Provider value={userAuthData}>{children}</AuthContextDetails.Provider>;
  }
};

const useAuthProvider = () => {
  const [userSession, setUserSession] = useState<any>();
  const [userCognitoAuthData, setUserCognitoAuthData] = useState<UserAuthContext>({
    ...initialData,
    error: '',
    userAuthDataLoadingStatus: LoadingStatus.Loading
  });

  useEffect(() => {
    Auth.configure(getAuthConfig());

    const configureAppSync = async () => {
      try {
        const awsAppSync = await getAppSyncConfig();
        Amplify.configure(awsAppSync.Appsync);

        const apiConfig = getApiConfig();
        Amplify.configure(apiConfig);
      } catch (error: any) {
        console.error('Unable to fetch AppSync Config', error);
      }
    };

    const signInWithAmazonFederate = async () => {
      try {
        const userSessionDetails = await Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
        setUserSession(userSessionDetails);
        const session = await Auth.currentSession();
        setUserCognitoAuthData(getSessionDetails(session));
      } catch (error: any) {
        console.error('Unable to sign in with AmazonFederate', error);
        setUserCognitoAuthData({
          ...userCognitoAuthData,
          error: 'Unable to sign in with AmazonFederate',
          userAuthDataLoadingStatus: LoadingStatus.Failed
        });
      }
    };

    Auth.currentAuthenticatedUser()
      .then(async (userSessionDetails) => {
        await configureAppSync();
        setUserSession(userSessionDetails);
        const session = await Auth.currentSession();
        setUserCognitoAuthData(getSessionDetails(session));
        console.log('Successfully Authenticated!!');
      })
      .catch(() => {
        signInWithAmazonFederate();
      });
  }, []);

  const getSessionDetails = (credentials: CognitoUserSession) => {
    let authLdapGroup: any = credentials.getIdToken().payload['custom:LDAP_GROUPS'];
    let UserLdapGroups: string[] = isEmpty(authLdapGroup) ? [] : authLdapGroup.split(',');
    return {
      Alias: credentials.getIdToken().payload['identities'][0].userId,
      DisplayName: credentials.getIdToken().payload['custom:DISPLAY_NAME'],
      Email: credentials.getIdToken().payload['custom:EMAIL'],
      UserLdaps: UserLdapGroups,
      error: '',
      userAuthDataLoadingStatus: LoadingStatus.Completed
    };
  };

  return userCognitoAuthData;
};

export const getAuthConfig = () => {
  return {
    Auth: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_lChyYcB1O',
      userPoolWebClientId: '158335lgh309ta4v53g3lkj7pu',
      identityPoolId: 'us-west-2:1280ed21-eea8-470d-8b19-17abf76f6988',
      awsAccountId: '645196202724',
      mandatorySignIn: true,
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        domain: 'das-marketing-spend-planner-alpha.auth.us-west-2.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: 'https://alpha.msp.device.finance.amazon.dev',
        redirectSignOut: 'https://alpha.msp.device.finance.amazon.dev',
        responseType: 'code'
      }
    }
  };
};

const getApiConfig = () => {
  const Stage: any = 'alpha';
  let apiConfig = {};
  if (Stage === 'prod') {
    apiConfig = {
      endpoints: [
        {
          name: 'PAPI',
          endpoint: 'https://ad3papi.device.finance.amazon.dev'
        }
      ]
    };
  } else {
    apiConfig = {
      endpoints: [
        {
          name: 'PAPI',
          endpoint: 'https://beta.ad3papi.device.finance.amazon.dev'
        }
      ]
    };
  }
  return apiConfig;
};

// Get App Sync details from AWS Secret Manager
const getAppSyncConfig = async () => {
  try {
    const Region: any = 'us-west-2';
    const Stage: any = 'alpha';
    let appSyncConfig: any = {};

    switch (Stage) {
      case 'test':
        // Retrieve API Key only happens in Test environment
        const apiKeySecretString = (await retrieveAPIKey()).SecretString;
        const apiKeyFromSecrets = (apiKeySecretString && JSON.parse(apiKeySecretString)['msp-app-apikey-secret']) || '';
        appSyncConfig = {
          Appsync: {
            aws_appsync_graphqlEndpoint: 'https://bwq624vz65f2fhlg7ob4hmhczy.appsync-api.us-west-2.amazonaws.com/graphql',
            aws_appsync_region: Region,
            aws_appsync_authenticationType: 'API_KEY',
            aws_appsync_apiKey: apiKeyFromSecrets
          }
        };
        break;
      case 'alpha':
        appSyncConfig = {
          Appsync: {
            aws_appsync_graphqlEndpoint: 'https://u2wxi47r2rblxkcwaley4jg7ae.appsync-api.us-west-2.amazonaws.com/graphql',
            aws_appsync_region: Region,
            aws_appsync_authenticationType: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS
          }
        };
        break;
      case 'beta':
        appSyncConfig = {
          Appsync: {
            aws_appsync_graphqlEndpoint: 'https://rfy22sxxuvd5jpk5rnakmzfrqm.appsync-api.us-west-2.amazonaws.com/graphql',
            aws_appsync_region: Region,
            aws_appsync_authenticationType: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS
          }
        };
        break;
      case 'prod':
        appSyncConfig = {
          Appsync: {
            aws_appsync_graphqlEndpoint: 'https://a33zwncf3fak7i2vychrhpaxyi.appsync-api.us-west-2.amazonaws.com/graphql',
            aws_appsync_region: Region,
            aws_appsync_authenticationType: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS
          }
        };
        break;
      default:
        break;
    }
    return appSyncConfig;
  } catch (error) {
    console.error('Unable to retrieve API Key ', error);
    throw error;
  }
};
